import moment from 'moment'

export const validateURL = (url, props, item, event) => {
    if (!url) return false

    const regex = new RegExp(/^(http|https):\/\/[^ "]+$/)
    return regex.test(url)
}

export const validateEmail = (email, props, item, event) => {
    if (!email) return false

    const regex = new RegExp(/\S+@\S+\.\S+/)
    return regex.test(email)
}

export const validateMultipleEmails = (emails, props, item, event) => {
    if (!emails) return false

    const emailArray = emails.trim().split(/\s+/)

    return emailArray.every(email => validateEmail(email, props, item, event))
}

export const validatePhone = (phone, props, item, event) => {
    if (!phone) return false

    const onlyNumbers = phone.toString().replace(/\D/g, '')

    return onlyNumbers.length >= 10
}

export const validateMultiplePhones = (phones, props, item, event) => {
    if (!phones) return false

    const phoneArray = phones.toString().trim().split(/\s+/)
    return phoneArray.every(phone => validatePhone(phone, props, item))
}

export const textTag = (text, props, item, event) => {
    if (!text) return false

    text = text.toString()

    if (props.required === 2) {        
        if (props.field in item
            && item[props.field] !== props.value) {
            return true
        }
    }

    const min = props.textTag?.min || 1
    console.log("text", text)
    var tags = text.split(",").map(t => t.trim())
    return tags.length >= min
}

export const minDate = (date, props, item, event) => {
    let min = props.min

    if (!min) return false
    if (!date) return false

    if (min[0] === "{") {
        console.log("event", event)
        min = min.substr(1, min.length - 2)
        min = event[min]
    }

    min = moment(min)
    let dateValue = moment(date)

    return dateValue >= min
}

export const validators = {
    "url": validateURL,
    "textTag": textTag,
    "email": validateEmail,
    "multipleEmails": validateMultipleEmails,
    "phone": validatePhone,
    "multiplePhones": validateMultiplePhones,
    "minDate": minDate,
}

export default validators