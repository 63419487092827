import React, { useEffect, useState } from "react";


function TextTagInput(props) {
    const [name] = useState(props.name || "");
    const [tags, setTags] = useState(props.tags || []);
    const [text, setText] = useState(props.text || "");
    const [className] = useState(props.className || "");
    const [inputText, setInputText] = useState('');


    useEffect(() => {
        let tags = text ? text.toString().split(",") : [];
        tags = tags.map((tag) => { tag = tag.trim(); return tag; })

        setTags(tags);
    }, [text]);

    const onKeyUp = (e) => {
        const acceptedKeys = ["Enter", ",", " "];
        let trimmedInput = e.target.value.toString().trim();

        if (acceptedKeys.includes(e.key) 
            && trimmedInput 
            && !tags.includes(trimmedInput)) {
            trimmedInput = trimmedInput.replace(/,/g, ""); 
            let t = [...tags, trimmedInput]
            
            setTags(t)
            setInputText("");

            changeValueCallback(t)
        }
        else if (e.target.value.includes(",")) {
            let newTags = e.target.value.split(",");
            newTags = newTags.map((tag) => { tag = tag.trim(); return tag; })

            newTags = newTags.filter((tag) => tag !== "" && !tags.includes(tag));

            const allTags = [...tags, ...newTags];
            setTags(allTags);
            setInputText("");

            changeValueCallback(allTags)            
        }
    }

    const deleteTag = (index) => {
        let t = tags.filter((_, i) => i !== index);

        setTags(t)
        changeValueCallback(t)
    }

    const changeValueCallback = (t) => {
        let sendTags = t || tags;

        if (props.onChange) {
            let event = {
                name: name,
                target: {
                    name: name,
                    type: "text",
                    tagName: "TextTagInput",
                    value: sendTags.join(", ")
                }
            }
            props.onChange(event);
        }
    }


    return (
       <div className="tags-input">
            <ul id="tags">
                {tags.map((tag, index) => (
                    <li key={index} className="tag">
                        <span>{tag}</span>
                        <b className="tag-close-icon" onClick={() => deleteTag(index)}>X</b>
                    </li>
                ))}
            </ul>
            <input
                type="text"
                value={inputText}
                className={className}
                onKeyUp={onKeyUp}
                onChange={(e) => setInputText(e.target.value)}
            />
       </div>
    )
}

export default TextTagInput;